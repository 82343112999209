<template>

    <html-frame :url = "patent.fileUrl"/>
   

</template>

<script>

import htmlFrame from "@/components/all/pdfHtmlFrame"; //引入尾部组件

export default {
  name: 'PatentDetail',
  components: {
    htmlFrame,

  },

  data() {
    return {   
      patent: {},
      id: '',
      title: '',
      primary: 1, //一级菜单的选中样式
      istrue: true, //刷新页面
      crumblist: [
        {
          name: "首页",
          path: "/home",
          query: {},
        },
        {
          name: "知识产权分析报告",
          path: "/patent",
          query: {},
        },
        {
          name: "报告细信息",
          path: "",
          query: {},
        },
      ],
    }
  },
  
  created() {
    this.init()
    this.getdata();
  },
  methods: {
    init(){
      this.id = this.$route.params.id
      console.log("   patent id " ,   this.id)
    },
    getdata() {
      this.$api.getPatentDetail(this.id).then((res) => {
        this.patent = res.data;
        this.title = this.patent.title
        console.log("  get patent " , this.patent)
      });
    },
  },
};
</script>
